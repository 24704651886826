import Vue from 'vue'
import Vuex from 'vuex'

import admin from './modules/admin'
import colors from './modules/colors'
import copy from './modules/copy'
import header from './modules/header'
import logo from './modules/logo'
import main from './modules/main'
import mainmtr from './modules/mainmtr'
import usuarios from './modules/usuarios'
import info from './modules/info'
import suscripciones from './modules/suscripciones'
import productos from './modules/productos'
import personalizacion from './modules/personalizacion'
import storeOnline from './modules/storeOnline'
import factura from './modules/factura'
import categories from './modules/categories'
import marcas from './modules/marcas'
import atributos from './modules/atributos'
import gallery from './modules/gallery'
import related from './modules/related'
import payment from './modules/payment'
import coupon from './modules/coupon'
import sociales from './modules/sociales'
import slides from './modules/slides'
import carrito from './modules/carrito'
import quotations from './modules/quotations'
import address from './modules/address'
import cursos from './modules/cursos'
import projectInfo from './modules/projectInfo'
import client from './modules/client'
import wishlist from './modules/wishlist'
import shipment from './modules/shipment'
import label from './modules/label'
import reviews from './modules/reviews'
import tendencias from './modules/tendencias'



Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    admin,
    colors,
    copy,
    header,
    logo,
    main,
    mainmtr,
    usuarios,
    info,
    suscripciones,
    productos,
    personalizacion,
    storeOnline,
    factura,
    categories,
    marcas,
    atributos,
    gallery,
    related,
    payment,
    client,
    coupon,
    sociales,
    slides,
    carrito,
    quotations,
    address,
    cursos,
    projectInfo,
    client,
    wishlist,
    shipment,
    label,
    reviews,
    tendencias
  }
})
